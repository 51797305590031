/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

// Main component for the application:
// - Sets up the initial state of the application
// - Handles hash changes
// - Handles beforeunload events
// - Handles errors
// - Renders the TopBar, LeftNav, Content, and FullScreenVisual components
// - Wraps the application in an ErrorBoundary component

import React from 'react';
import ErrorStackParser from 'error-stack-parser';

import Content from './Content';
import OnboardingWizard from '../OnboardingWizard';
import ContextProviderWrapper from './ContextProviderWrapper';

import { ErrorBoundary } from 'react-error-boundary';

import {
  decodeURLHash,
  getGlobalSettings,
  isEmpty,
  isNotEmpty,
  itemIsFunction,
} from '../../shared/Utilities';

import './Scanning.scss';
import './Reporting.scss';
import { makeRequest } from '../../../legacy/io';
import ErrorFallback from './Error';
import TopBar from '../Navigation/TopBar';
import LeftNav from '../Navigation/LeftNav';
import FullScreenVisual from '../../shared/FullScreenVisual';

const App = () => {

  const [ currentLocation, setCurrentLocation ] = React.useState( { } );
  const [ syncHelpURL, setSyncHelpURL ] = React.useState( null );
  const [ hoveringOverLeftNav, setHoveringOverLeftNav ] = React.useState( false );

  // main hash change handler for setting the current location
  const handleHashChange = () => {
    const hash = decodeURLHash();
    const route = hash['.'];
    const { page, report } = hash;

    if ( isEmpty( route ) ) {
      window.location.href = '#.=reporting&page=reporting_dashboard';
    } else {
      setCurrentLocation( { route, page, report } );
    }
    const pageContentElement = document.getElementById( 'pageContent' );

    if (
      isNotEmpty( pageContentElement )
      && isNotEmpty( pageContentElement.onleave )
      && itemIsFunction( pageContentElement.onleave )
    ) {
      handleOnLeave( pageContentElement );
    }

  };

  // some forms have onleave functions that need to be called when the user navigates away from a page
  const handleOnLeave = async ( element ) => {
    if (
      isNotEmpty( element )
      && isNotEmpty( element.onleave )
      && itemIsFunction( element.onleave )
    ) {
      await element.onleave();
    }
    return undefined;
  };

  // handle the beforeunload event
  const handleBeforeUnload = async () => {
    const pageContentElement = document.getElementById( 'pageContent' );

    if (
      isNotEmpty( pageContentElement )
      && isNotEmpty( pageContentElement.onleave )
      && itemIsFunction( pageContentElement.onleave )
    ) {
      handleOnLeave( pageContentElement );
    }
    return undefined;
  };

  // If the FE encounters an error, this function will be called and passed to the ErrorBoundary component
  const handleError = ( errorEvent ) => {
    if ( isNotEmpty( errorEvent ) ) {
      const errorObject = new Error( errorEvent.message );
      const stackFrames = ErrorStackParser.parse( errorEvent );
      let stackFramesString = '';

      const { message, name } = errorEvent;

      const errorParams = {
        error: {
          message: message || '',
        },
      };

      if ( isNotEmpty( stackFrames ) ) {
        const stackFrameStrings = [];
        stackFrames.forEach( ( frame ) => {
          // create a string that concatenates the stack frame properties
          // eslint-disable-next-line max-len
          let stackFrameAsString = `${frame.fileName}:${frame.lineNumber}:${frame.columnNumber}`;

          if ( isNotEmpty( frame.functionName ) ) {
            stackFrameAsString = `${stackFrameAsString} ${frame.functionName}`;
          }
          stackFrameAsString = `${stackFrameAsString}  ${frame.source}`;
          stackFrameStrings.push( stackFrameAsString );
        } );

        stackFramesString = stackFrameStrings.join( '\n' );
      }

      if (
        isNotEmpty( errorParams.error )
        && isNotEmpty( errorObject )
        && isNotEmpty( stackFramesString )
      ) {
        errorParams.error.name = name;
        errorParams.error.location = window.location.href;
        errorParams.error.stack = stackFramesString;
      }

      makeRequest( 'ERROR', '/front_end_reports', errorParams );
    }
  };

  // initial load of the application, need to setup a few things
  // 1. get the help url for clock sync
  // 2. setup the recordCache, flashMessageQueue, capacity, and duration globals
  // 4. setup hashChange listener
  React.useEffect( () => {
    setSyncHelpURL( '#.=help_documentation&main=reference_guide&section=other_reference_items&help_page=clock_sync' );

    getGlobalSettings( 'global' ).then( response => {
      window.globalSettings = response;
    } );

    // global recordCache var
    if ( !window.recordCache ) {
      window.recordCache = new Map();
    }

    // global dashboard recordCache var
    if ( !window.dashboardCache ) {
      window.dashboardCache = {};
    }
    // global flashMessageQueue var
    if ( !window.flashMessageQueue ) {
      window.flashMessageQueue = new Map();
    }
    window.CACHE_CAPACITY = 10_000;
    window.DEFAULT_FLASH_MESSAGE_DURATION = 5_000;
    window.FLASH_MESSAGE_CAPACITY = 4;

    const hash = decodeURLHash();
    if ( isEmpty( hash['.'] ) ) {
      window.location.href = '#.=reporting&page=reporting_dashboard';
    }
    handleHashChange();
    window.addEventListener( 'hashchange', handleHashChange );
    window.addEventListener( 'beforeunload', handleBeforeUnload );
    return () => {
      window.removeEventListener( 'hashchange', handleHashChange );
      window.removeEventListener( 'beforeunload', handleBeforeUnload );
      window.dashboardCache = {};
    };
  }, [] );

  const pageClassName = () => {
    if ( isNotEmpty( currentLocation.report ) ) {
      return currentLocation.report;
    } else if ( isNotEmpty( currentLocation.page ) ) {
      return currentLocation.page;
    }
    return 'default';
  };

  return (
    <ErrorBoundary
      FallbackComponent={ ErrorFallback }
      onError={ handleError }
    >
      {
        isNotEmpty( currentLocation ) &&
        <ContextProviderWrapper>
          <OnboardingWizard />
          <TopBar
            currentLocation={currentLocation}
            hoveringOverLeftNav={hoveringOverLeftNav}
            setHoveringOverLeftNav={setHoveringOverLeftNav}
          />
          <LeftNav
            hoveringOverLeftNav={hoveringOverLeftNav}
            setHoveringOverLeftNav={setHoveringOverLeftNav}
          />
          <Content currentLocation={currentLocation} pageClassName={ pageClassName } syncHelpURL={syncHelpURL} />
          <FullScreenVisual />
        </ContextProviderWrapper>
      }

    </ErrorBoundary>
  );
};

export default App;