/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

// The tasks are the main engine that drives DeepSurface Analysis.
// The tasks are ordered from top to bottom in the natural order that they are executed.
// However, the user can kick off any task at any time.
// The tasks are:
// - upgrade (force an upgrade of the DeepSurface software to the latest version)
// eslint-disable-next-line max-len
// - feed (ingest the DeepSurface rules feed, this is scheduled by default to handle daily, which will cascade to all other tasks running in sequence)
// - authenticated (run the credentialed scan)
// - process (process the data from the credentialed scan and/or agents and user-managed scripts)
// - import (import the third party vuln. source information into the DeepSurface database)
// - analysis (run the analysis engine to determine the risk and remediation steps)

import React from 'react';
import { TasksProvider } from '../../../Contexts/Tasks';

import TaskList from './TaskList';
import './style.scss';
import ConfigurationAlert, { getConfigurationAlertsFor } from '../../../shared/ConfigurationAlert';
import { isNotEmpty } from '../../../shared/Utilities';
import { hasFeatureAccess } from '../../App/AccessControl';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';

const Tasks = ( ) => {
  const [ hasDiskAlert, setHasDiskAlert ] = React.useState( false );
  const [ currentUser, licenseInfo ] = React.useContext( CurrentUserContext );

  React.useEffect( () => {
    if ( isNotEmpty( currentUser ) && hasFeatureAccess( currentUser, licenseInfo, 'f_configuration_alerts' ) ) {
      getConfigurationAlertsFor( 'health-check' ).then( response => {
        if ( isNotEmpty( response ) ) {
          setHasDiskAlert( true );
        }
      } );
    }
  }, [ currentUser, licenseInfo ] );

  return (
    <div className="setupPageContainer tasksPage">
      <TasksProvider>
        {
          hasDiskAlert &&
          <ConfigurationAlert
            sourceType="health-check"
          />
        }
        <TaskList />
      </TasksProvider>
    </div>
  );
};

export default Tasks;