/** **************************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
****************************************************************************/

// The DataTable component is a hugely important component in the DeepSurface UI. It is used anywhere tabular data
// is displayed.
// The primary use is within the risk insight reports index pages, but it is also used in other places as well.
// The DataTable component has gone through several revisions and currently uses css-grid instead of an actual table
// this approach has several benefits and some drawbacks.
// Benefits:
// - The grid layout is more flexible than a table layout and allows cells to contain more dynamic content and styling

// Drawbacks:
// - The table will need to be styled for each use-case appropriately. Specific CSS must be written to tell the
//   grid how many columns it has and how each column should grow and shrink. A better default could be used that allows
//   the grid to grow and shrink based on the content of the cells, and there are methods that allow this, but they have
//   not been implemented yet.

import React from 'react';
import {
  isNotEmpty,
  capitalize,
  isEmpty,
  decodeURLHash,
  encodeURLHash,
  triggerHashRefresh,
  itemIsArray,
} from '../Utilities';

import EmptyState from '../EmptyState';
import InlineSVG from '../InlineSVG';

import './style.scss';
import { HelpTrigger } from '../../components/HelpDocumentation/ContextualHelp';

const cellKeyOrderByMap = {
  // host table
  risk: [ 'filtered_risk' ],
  name: [ 'local_name', 'identifier' ], // includes identifer for patch/vuln.
  vulnerabilities: [ 'num_vulnerabilities' ],
  // eslint-disable-next-line camelcase
  all_patches: [ 'num_patches' ],
  // eslint-disable-next-line camelcase
  product_name: [ 'product_name' ],
  // eslint-disable-next-line camelcase
  unsuperseded_patches: [ 'num_unsuperseded_patches' ],
  'DeepSurface Scanning Status': [ 'last_scanned' ],

  // patch table
  // eslint-disable-next-line camelcase
  superseded_patches: [ 'num_superseded_patches' ],
  // eslint-disable-next-line camelcase
  affected_hosts: [ 'num_hosts' ],

  // vuln table
  'CVSS': [ 'cvss_base_score' ],
  // eslint-disable-next-line camelcase
  exploit_status: [ 'exploit_status' ],

  // signature table
  // eslint-disable-next-line camelcase
  scanner_signature: [ 'scanner' ],
  vulns: [ 'num_vulnerabilities' ],
  hosts: [ 'num_hosts' ],
};

const Thead = ( {
  headerData,
  onRefresh=() => {},
  sortableColumns,
  withSelect,
  selectedIDs,
  setSelectedIDs,
  recordCount,
  data,
  withQuickView,
} ) => {

  const headerKeys = Object.keys( headerData );
  const sortableKeys = isNotEmpty( sortableColumns ) ? Object.keys( sortableColumns ) : [];

  const [ orderBy, setOrderBy ] = React.useState( '' );
  const [ orderDirection, setOrderDirection ] = React.useState( 'DESC' );

  // adding a help icon to some cells, if this becomes more of a thing, we will need a way to pass this in
  const helpNeededCells = [
    'exploit_status',
    'exporting_to',
    'exported_to',
  ];

  const handleSortableClick = keyName => {
    const hash = decodeURLHash();
    const _newOrderBy = sortableColumns[keyName];

    if ( isNotEmpty( _newOrderBy ) ) {
      const { order_by } = hash;

      // set the new order by, later we will decide if we need to toggle the direction
      const order = [ _newOrderBy ];

      if ( isNotEmpty( order_by ) && itemIsArray( order_by ) ) {
        // eslint-disable-next-line camelcase
        const [ primary ] = order_by;
        const [ primaryOrderBy, primaryOrderDirection ] = primary;
        // toggle the direction, if we are clicking on the same key
        if ( primaryOrderBy === _newOrderBy ) {
          if ( primaryOrderDirection === 'DESC' ) {
            order.push( 'ASC' );
          } else {
            order.push( 'DESC' );
          }
        } else {
          order.push( 'DESC' );
        }
      }
      // eslint-disable-next-line camelcase
      encodeURLHash( { order_by: [ order ] } );
      triggerHashRefresh();
      onRefresh();
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( headerData ) ) {
      const hash = decodeURLHash();
      // grab the first order by and set it, if there are secondary ones, ignore them
      if ( isNotEmpty( hash ) ) {
        const { order_by } = hash;
        if ( isNotEmpty( order_by ) && itemIsArray( order_by ) ) {
          // eslint-disable-next-line camelcase
          const [ primary ] = order_by;
          setOrderBy( primary[0] );
          setOrderDirection( primary[1] );
        }
      }
    }
  }, [ headerData ] );

  const handleSelectAll = () => {
    if ( selectedIDs.length === recordCount ) {
      setSelectedIDs( [] );
    } else {
      const allIDs = data.map( i => i.id );
      setSelectedIDs( allIDs );
    }
  };

  return (
    <div
      // eslint-disable-next-line max-len
      className={ `tableHeader tableRow ${isNotEmpty( headerKeys.includes( 'rowColumnClass' ) ) ? headerData.rowColumnClass : ''}` }
    >
      {
        withSelect &&
        <div className="tableCell headerCell selectCell">
          <button
            className="tableSelectButton"
            onClick={ handleSelectAll }
          >
            {
              selectedIDs.length === recordCount
                ? <InlineSVG type="remove" />
                : <InlineSVG type="checkbox" />
            }
          </button>
        </div>
      }
      {
        headerKeys.map( ( keyName, index ) => {
          if ( keyName === 'actions' ) {
            return <div key={index} className={ `tableCell headerCell headerCell_${keyName}` }></div>;
          } else if ( keyName !== 'id' && keyName !== 'originalRecord' && keyName !== 'rowColumnClass' ) {
            return  <React.Fragment key={index}>
              {
                sortableKeys.includes( keyName )
                  ? <div
                    // eslint-disable-next-line max-len
                    className={ `tableCell headerCell headerCell_${keyName} isSortable ${isNotEmpty( cellKeyOrderByMap[keyName] && cellKeyOrderByMap[keyName].includes( orderBy ) ) ? 'sortingBy' : ''} ${orderDirection}` }
                    onClick={ () => handleSortableClick( keyName ) }
                  >
                    <span>{ capitalize( keyName ) }</span>
                    {
                      helpNeededCells.includes( keyName ) &&
                      <HelpTrigger helpKey={keyName}/>
                    }
                    <InlineSVG
                      type="sortAsc"
                      version="special"
                      elementClass="ascending"
                      size="small"
                    />
                    <InlineSVG
                      type="sortDesc"
                      version="special"
                      elementClass="descending"
                      size="small"
                    />
                    <InlineSVG type="sortBoth" version="special" elementClass="both" size="small" />
                  </div>
                  : <div
                    // eslint-disable-next-line max-len
                    className={ `tableCell headerCell headerCell_${keyName} ${isNotEmpty( cellKeyOrderByMap[keyName] && cellKeyOrderByMap[keyName].includes( orderBy ) ) ? 'sortingBy' : ''}` } >
                    <span>{ capitalize( keyName ) }</span>
                    {
                      helpNeededCells.includes( keyName ) &&
                      <HelpTrigger helpKey={keyName}/>
                    }
                  </div>
              }
            </React.Fragment>;
          }
        } )
      }
      { withQuickView && <div className="tableCell headerCell" /> }
    </div>
  );
};

const Tbody = ( {
  bodyData,
  sortableColumns,
  withSelect,
  selectedIDs,
  onSelect,
  editable,
  externalHoverRecord,
  setExternalHoverRecord,
  withQuickView,
  currentQuickView,
  toggleCurrentQuickView,
  rowAction,
  allowHover,
} ) => {
  return (
    <React.Fragment>
      {
        bodyData.map(
          ( row, index ) => <Row
            key={ index }
            row={ row }
            sortableColumns={ sortableColumns }
            withSelect={ withSelect }
            selectedIDs={ selectedIDs }
            onSelect={ onSelect }
            editable={ editable }
            externalHoverRecord={externalHoverRecord}
            setExternalHoverRecord={setExternalHoverRecord}
            withQuickView={withQuickView}
            currentQuickView={currentQuickView}
            toggleCurrentQuickView={toggleCurrentQuickView}
            rowAction={rowAction}
            allowHover={allowHover}
          />,
        )
      }
    </React.Fragment>
  );
};

const Row = ( {
  row,
  sortableColumns,
  withSelect,
  selectedIDs,
  onSelect,
  editable,
  rowAction,
} ) => {
  const ref = React.useRef( null );
  const isSelected = isNotEmpty( selectedIDs ) ? selectedIDs.includes( row.id ) : false;

  // for instances table, to know if a filter is being applied that includes this particular row
  const isFilteringBy = () => {
    const hash = decodeURLHash();
    const _groupType = hash.group_type;

    if ( isNotEmpty( _groupType ) ) {
      return hash[`${_groupType}_ids`] && hash[`${_groupType}_ids`].includes( row.id );
    }
    return false;
  };

  return (
    <div
      // eslint-disable-next-line max-len
      className={ `${isNotEmpty( row.rowColumnClass ) ? row.rowColumnClass : ''} ${ isNotEmpty( rowAction ) ? 'hasRowAction' : '' } ${isFilteringBy() ? 'filteringBy' : ''} tableRow ${isSelected ? 'isSelected' : ''}` }
      ref={ref}
      onClick={ isNotEmpty( rowAction ) ? () => rowAction( row.id ) : () => {} }
    >
      {
        withSelect
        && <Cell
          row={row}
          editable={editable}
          onSelect={onSelect}
          isSelectCell
          isSelected={isSelected}
        />
      }
      {
        Object.entries( row ).map( ( [ key, value ], index ) => {
          if ( key !== 'id' && key !== 'originalRecord' && key !== 'rowColumnClass' ) {
            return  <Cell
              sortableColumns={sortableColumns}
              key={index}
              cellKey={key}
              cellValue={value}
              row={row}
              editable={editable}
              onSelect={onSelect}
              isSelected={isSelected}
            />;
          }
        } )
      }
    </div>
  );
};

const Cell = ( {
  row,
  isSelected,
  editable=null,
  cellKey=null,
  cellValue=null,
  isSelectCell=null,
  onSelect=() => {},

} ) => {

  const inputRef = React.useRef( null );

  const isEditable = isNotEmpty( editable ) && Object.keys( editable ).includes( cellKey );

  const isNumber = isEditable && editable[cellKey].type === 'number';

  const [ orderBy, setOrderBy ] = React.useState( '' );

  // eslint-disable-next-line no-unused-vars
  const [ originalValue, setOriginalValue ] = React.useState( '' );

  // any time the row (and cell) change, we need to check whether to show the update or the original
  React.useEffect( () => {

    const hash = decodeURLHash();
    // grab the first order by and set it, if there are secondary ones, ignore them
    if ( isNotEmpty( hash ) ) {
      const { order_by } = hash;
      if ( isNotEmpty( order_by ) && itemIsArray( order_by ) ) {
        // eslint-disable-next-line camelcase
        const [ primary ] = order_by;
        setOrderBy( primary[0] );
      }
    }

    setOriginalValue( '' );
    // show either the updated value, or the original value
    if ( isNotEmpty( inputRef ) && isNotEmpty( inputRef.current ) && isEditable ) {
      if ( isEmpty( editable[cellKey].updatedValue( row ) ) ) {
        if ( isNumber ) {
          inputRef.current.valueAsNumber = row[cellKey];
        } else {
          inputRef.current.value = row[cellKey];
        }
      } else if ( isNumber ) {
        inputRef.current.valueAsNumber = editable[cellKey].updatedValue( row );
      } else {
        inputRef.current.value = editable[cellKey].updatedValue( row );
      }
    }

    if ( isNotEmpty( row[cellKey] ) ) {
      setOriginalValue( row[cellKey] );
    } else {
      setOriginalValue( '' );
    }

  }, [ row ] );

  const isUpdated = () => {
    if ( isEditable ) {
      return isNotEmpty( editable[cellKey].updatedValue( row ) );
    }
    return false;

  };

  const isError = () => {
    if ( isEditable ) {
      return isNotEmpty( editable[cellKey].error( row ) );
    }
    return false;

  };

  return (
    <React.Fragment>
      {
        isSelectCell
          ? <div className="tableCell selectCell" >
            <button
              className="tableSelectButton"
              onClick={ () => onSelect( row.id ) }
            >
              {
                isSelected
                  ? <InlineSVG type="checkboxChecked" />
                  : <InlineSVG type="checkbox" />
              }
            </button>
          </div>
          : <React.Fragment>
            <div
              // eslint-disable-next-line max-len
              className={`tableCell ${isNotEmpty( cellKeyOrderByMap[cellKey] && cellKeyOrderByMap[cellKey].includes( orderBy ) ) ? 'sortingBy' : ''} tableCell_${cellKey}`}
            >
              {
                isEditable
                  // eslint-disable-next-line max-len
                  ? <div className={`dataTableInputWrapper ${isUpdated() ? 'updated' : ''} ${isError() ? 'error' : ''}`}>
                    <input
                      type={ editable[cellKey].type }
                      onChange={ e => editable[cellKey].onEdit( row, e.target.valueAsNumber || e.target.value ) }
                      ref={inputRef}
                      { ...editable[cellKey].htmlProps }
                    />
                  </div>
                  : cellValue
              }
            </div>
          </React.Fragment>
      }
    </React.Fragment>
  );
};

const DataTable = ( {
  data,
  sortableColumns={},
  withSelect=false,
  selectedIDs,
  setSelectedIDs,
  onSelect,
  multiline=false,
  elementClass='',
  editable={},
  externalHoverRecord=null,
  setExternalHoverRecord=() => {},
  scrollToTableTop=false,
  rowAction=null,
  allowHover=false,
  variant='default',
  onRefresh=() => {},
} ) => {
  // allows DataTable parent to control page/pagination scroll
  const dataTableRef = React.useRef( null );
  const [ columnClass, setColumnClass ] = React.useState( 'dataTable_0_columns' );

  const scrollToDataTableTop = () => {
    if ( isNotEmpty( dataTableRef ) && isNotEmpty( dataTableRef.current ) ) {
      dataTableRef.current.scrollTop = 0;
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( data ) ) {
      const keys =  Object.keys( data[0] );
      let numCols = keys.length;
      if ( keys.includes( 'id' ) ) {
        numCols -= 1;
      }
      setColumnClass( `dataTable_${numCols}_columns` );
    }

    if ( scrollToTableTop ) {
      scrollToDataTableTop();
    }
  }, [ data, scrollToTableTop ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( data )
          // eslint-disable-next-line max-len
          ? <div ref={dataTableRef} className={`${elementClass} ${columnClass} dataTable ${withSelect ? 'withSelect' : ''} ${multiline ? 'multiline' : ''} ${variant}`}>
            <Thead
              headerData={ data[0] }
              data={ data }
              recordCount={ data.length }
              sortableColumns={ sortableColumns }
              withSelect={withSelect}
              selectedIDs={selectedIDs}
              setSelectedIDs={setSelectedIDs}
              onRefresh={onRefresh}
            />
            <Tbody
              bodyData={ data }
              sortableColumns={ sortableColumns }
              withSelect={withSelect}
              onSelect={ onSelect }
              selectedIDs={ selectedIDs }
              editable={editable}
              externalHoverRecord={externalHoverRecord}
              setExternalHoverRecord={setExternalHoverRecord}
              rowAction={rowAction}
              allowHover={allowHover}
            />
          </div>
          : <EmptyState message={ 'Your filters did not return any results' }/>
      }
    </React.Fragment>
  );
};

export default DataTable;