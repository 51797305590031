/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

//  Multi variant of the bar chart

import React from 'react';
import { globalColors, isEmpty, isNotEmpty } from '../Utilities';

import './MultiBar.scss';

const MultiBar = ( {
  data,
  onHover=null,
  onClick=null,
  hoveredSeriesIdentifier,
  setHoveredSeriesIdentifier,
  svgAspectRatio=null,
} ) => {

  const [ max, setMax ] = React.useState( 1 );
  const [ gap, setGap ] = React.useState( 0 );
  const [ seriesWidth, setSeriesWidth ] = React.useState( 0 );
  const [ containerHeight, setContainerHeight ] = React.useState( null );
  const containerWidth = 100;

  // const xOffset = 0;
  const yOffset = 0;
  const minHeight = 1;

  const handleMouseEnter = series => {
    if ( isNotEmpty( setHoveredSeriesIdentifier ) ) {

      setHoveredSeriesIdentifier( series.key );
      if ( isNotEmpty( onHover ) ) {
        onHover( series );
      }
    }
  };

  const handleMouseLeave = () => {
    if ( isNotEmpty( setHoveredSeriesIdentifier ) ) {
      setHoveredSeriesIdentifier( null );
      if ( isNotEmpty( onHover ) ) {
        onHover( null );
      }
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( data ) && isNotEmpty( data.series ) ) {

      if ( isNotEmpty( svgAspectRatio ) ) {
        setContainerHeight( containerWidth / svgAspectRatio );
      } else {
        setContainerHeight( 50 );
      }

      const _seriesCount = data.series.length;
      const _seriesPlusGap = containerWidth / _seriesCount;
      const _gap = _seriesCount >= 50 ? _seriesPlusGap * 0.15 : _seriesPlusGap * 0.1;

      const _seriesWidth = _seriesPlusGap - _gap;

      setSeriesWidth( _seriesWidth );
      setGap( _gap );

      // set the y axis max
      if ( isEmpty( data.max ) ) {
        let _seriesMax = 0;

        data.series.map( series => {
          series.map( point => {
            if ( point.value > _seriesMax ) {
              _seriesMax = point.value;
            }
          } );
        } );
        setMax( _seriesMax );
      } else {
        setMax( data.max );
      }
    }
  }, [ data, svgAspectRatio ] );

  return (
    <React.Fragment>
      <div className="svgMultiBarWrapper">
        {
          (
            isNotEmpty( data )
            && isNotEmpty( max )
            && isNotEmpty( data.series )
            && isNotEmpty( seriesWidth )
            && isNotEmpty( gap )
            && isNotEmpty( containerWidth )
            && isNotEmpty( containerHeight )
            && isFinite( containerHeight )
          ) &&
          <svg
            viewBox={ `0 0 ${containerWidth} ${containerHeight}` }
            xmlns="http://www.w3.org/2000/svg"
            className="multiBarChart"
            preserveAspectRatio="none"
            fill="none"
          >
            {
              data.series.map( ( series, index ) => {
                const _max = max <= 0 ? 1 : max;

                // a transparent rect that holds both bars in the series, mouseEvents happen here
                return <g
                  key={index}
                  className="seriesContainerRectangle"
                  onMouseEnter={ () => handleMouseEnter( series ) }
                  onMouseLeave={ handleMouseLeave }
                >
                  {
                    series.map( ( bar, _index ) => {

                      const strokeWidth = 0.25;

                      let height = 0;

                      height = ( containerHeight - yOffset ) * ( bar.value / _max );

                      if ( height < minHeight || isEmpty( height ) || isNaN( height ) ) {
                        height = minHeight;
                      }

                      if ( height === containerHeight - yOffset ) {
                        height = height - strokeWidth;
                      }

                      const y = ( ( containerHeight - yOffset ) - height );

                      const barGap = gap / 2;
                      const barWidth = ( seriesWidth / 2 ) - barGap;

                      const seriesBarOffset = _index === 0 ? 0 : barWidth + barGap;

                      const x = seriesBarOffset // 0 or barwidth
                        + ( seriesWidth + gap ) * index; // add how for over it should be based on the parent index

                      return <g
                        key={_index}
                        // eslint-disable-next-line max-len
                        className={ `${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === bar.key ? 'isHovered' : '' } fullBarWrapper ${ isNotEmpty( onClick ) ? 'clickable' : '' }` }
                        onClick={ onClick ? e => onClick( e.target, e ) : () => {} }
                      >
                        {/* full bar vertical background */}
                        <rect
                          id={
                            bar?.original?.id
                              ? `hoverIndicatorID_${bar?.original?.id}`
                              : `randomIndicator_${Math.random()}`
                          }
                          // eslint-disable-next-line max-len
                          className={ `${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === bar.key ? 'isHovered' : '' } barContainer`}
                          y="0"
                          x={ x }
                          width={ ( barWidth ) }
                          rx="0"
                          height={ containerHeight - yOffset }
                          fill={ globalColors['grey--background'] }
                          onMouseEnter={ () => handleMouseEnter( bar ) }
                          onMouseLeave={ handleMouseLeave }

                        />
                        {/* actual bar segment */}
                        <rect
                          id={
                            bar?.original?.id
                              ? `barSegmentID_${bar?.original?.id}`
                              : `randomSegment_${Math.random()}`
                          }
                          // eslint-disable-next-line max-len
                          className={ `${ bar.outline ? 'outline' : '' } ${ isNotEmpty( setHoveredSeriesIdentifier ) ? 'isHoverable' : '' } ${ hoveredSeriesIdentifier === bar.key ? 'isHovered' : '' } barSegment`}
                          y={ y === 0 ? strokeWidth : y - ( strokeWidth / 2 ) }
                          x={ x + ( strokeWidth / 2 ) }
                          width={ barWidth === 0 ? 0 : barWidth - ( strokeWidth ) }
                          height={ height }
                          fill={ bar.fill }
                          fillOpacity={ bar.isTag ? '0.8' : '1' }
                          stroke={ bar.stroke }
                          strokeOpacity={ bar.isTag ? '0.8' : '1' }
                          strokeWidth={ strokeWidth }
                          onMouseEnter={ () => handleMouseEnter( bar ) }
                          onMouseLeave={ handleMouseLeave }
                        />
                      </g>;
                    } )
                  }
                </g>;
              } )
            }
          </svg>
        }
      </div>
    </React.Fragment>
  );
};

export default MultiBar;