/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';

import EmptyState from '../../../../EmptyState';

import SelectedItemWrapper from './SelectedItemWrapper';

import './style.scss';

import {
  isEmpty,
  isNotEmpty,
} from '../../../../Utilities';

import InlineSVG from '../../../../InlineSVG';
import Modal from '../../../../Modal';

const InputBody = ( {
  availableOptions,
  items,
  setItems,
  field,
  formState,
  selections,
  handleMultiSelectChange,
  handleSelection,
  singleSelectValue,
  onDragEnd,
  onChange,
} ) => {
  return (
    <React.Fragment>
      {
        isNotEmpty( availableOptions ) &&
        <React.Fragment>
          {
            field.allowMultiple
              ? <div className="multiSelectFieldWrapper">
                <select
                  disabled={field.disabled || formState?.fieldStates[field.attribute].disabled}
                  value={ selections }
                  onChange={ handleMultiSelectChange }
                  multiple
                  className="mulitSelect"
                >
                  {
                    Object.keys( availableOptions ).map( ( opt, index ) => {
                      return  <option
                        key={index}
                        value={opt}
                      >
                        { field.options[opt] }
                      </option>;
                    } )
                  }
                </select>
                <button
                  disabled={isEmpty( selections )}
                  title="Add Selected Recipients"
                  onClick={ e => handleSelection( e.target.value ) }
                >
                  Select { field.label }
                  {
                    field.vertical
                      ? <InlineSVG type="arrowUp" version="light" />
                      : <InlineSVG type="arrowRight" version="light" />
                  }
                </button>
              </div>
              : <div
                className="newItemWrapper"
              >
                <div className="selectFieldWrapper">
                  <select
                    disabled={field.disabled || formState?.fieldStates[field.attribute].disabled}
                    value={ singleSelectValue }
                    onChange={ e => handleSelection( e.target.value, true ) }
                  >
                    <option value="" disabled >Select...</option>
                    {
                      Object.keys( availableOptions ).map( ( opt, index ) => {
                        return  <option
                          key={index}
                          value={opt}
                        >
                          { field.options[opt] }
                        </option>;
                      } )
                    }
                  </select>
                </div>
              </div>
          }
        </React.Fragment>
      }
      {
        isNotEmpty( items )
          ? <div className="selectedItems">
            {
              field.needsDraggable
                ? <DragDropContext onDragEnd={ result => onDragEnd( result, items )}>
                  <Droppable
                    droppableId="droppable"
                    isDropDisabled={field.disabled || formState?.fieldStates[field.attribute].disabled}
                  >
                    {
                      ( provided, snapshot ) => (
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className={`${snapshot.isDraggingOver ? 'isDragging' : ''}`}
                        >
                          {
                            Object.keys( items ).map( ( id, index ) => {
                              return  <SelectedItemWrapper
                                key={index}
                                order={index + 1}
                                id={id}
                                items={items}
                                setItems={setItems}
                                field={field}
                                onChange={onChange}
                                index={index}
                              />;
                            } )
                          }
                          {provided.placeholder}
                        </ul>
                      )
                    }
                  </Droppable>
                </DragDropContext>
                : <ul>
                  {
                    Object.keys( items ).map( ( id, index ) => {
                      return  <SelectedItemWrapper
                        key={index}
                        order={index + 1}
                        id={id}
                        items={items}
                        setItems={setItems}
                        field={field}
                        onChange={onChange}
                        index={index}
                      />;
                    } )
                  }
                </ul>
            }

          </div>
          : <EmptyState message={ `No ${field.label} Selected` } />
      }
    </React.Fragment>
  );
};

const SelectList = ( {
  field,
  originalValue,
  formState,
  onChange,
  existingRecord,
} ) => {

  const [ items, setItems ] = React.useState( {} );
  const [ selections, setSelections ] = React.useState( [] );
  const [ singleSelectValue, setSingleSelectValue ] = React.useState( '' );
  const [ availableOptions, setAvailableOptions ] = React.useState( {} );

  const [ showModal, setShowModal ] = React.useState( false );

  // if there are any existing items, break them out and get them ready
  const parseSelectedItems = itemsFromServer => {
    const allItems = {};

    itemsFromServer.map( id => {
      if ( isNotEmpty( field.options[id] ) ) {
        allItems[id] = field.options[id];
      }
    } );
    setItems( allItems );
    onChange( field, Object.keys( allItems ) );
  };

  // 1) First thing to happen with values from the server
  React.useEffect( () => {
    if ( isNotEmpty( originalValue ) && isNotEmpty( existingRecord ) ) {
      parseSelectedItems( originalValue );
    } else {
      setItems( {} );
    }
  }, [ originalValue, existingRecord ] );


  // 2) remove selected items from the options available in the selected
  React.useEffect( () => {
    const tmpOptions  = {};

    if ( isEmpty( items ) ) {
      setAvailableOptions( field.options );
    } else {

      const itemIDs = [];

      // push all the ids of the items that have already been selected
      Object.keys( items ).map( id => {
        itemIDs.push( id );
      } );

      // compare all options with ones that have already been chosen
      if ( isNotEmpty( field.options ) ) {
        Object.keys( field.options ).map( id => {
          if ( !itemIDs.includes( id ) ) {
            tmpOptions[id] = field.options[id];
          }
        } );
      }


      setAvailableOptions( tmpOptions );
    }
  }, [ items, field ] );

  const handleMultiSelectChange = e => {
    const value = Array.from( e.target.selectedOptions, option => option.value );
    setSelections( value );
  };

  const handleSelection = ( _value, isSingleSelect=false )  => {
    const value = {};

    if ( isNotEmpty( items ) ) {
      Object.keys( items ).map( id => {
        value[id] = items[id];
      } );
    }

    if ( isSingleSelect ) {
      setSingleSelectValue( _value );
      value[_value] = field.options[_value];
    } else {
      selections.map( id => {
        value[id] = field.options[id];
      } );
    }
    setItems( value );
    onChange( field, Object.keys( value ) );
    setSingleSelectValue( '' );
    setSelections( [] );
  };

  const reorder = ( list, startIndex, endIndex ) => {

    const newList = {};
    const result = Array.from( Object.keys( list ) );
    const [ removed ] = result.splice( startIndex, 1 );
    result.splice( endIndex, 0, removed );

    result.map( id => {
      newList[id] = list[id];
    } );

    setItems( newList );
    onChange( field, Object.keys( newList ) );
  };

  const onDragEnd = ( result, items ) => {
    // dropped outside the list
    if ( !result.destination ) {
      return;
    }

    reorder(
      items,
      result.source.index,
      result.destination.index,
    );
  };

  return (
    <React.Fragment>
      <label>
        <span className="labelWrapper">
          { field.label }
          {
            field.required &&
            <span className="required">*</span>
          }
          { field.help && field.help }
        </span>
      </label>
      <div
        className={`selectListContainer ${field.vertical ? 'asVertical' : ''} ${field.asModal ? 'asModal' : ''}`}
      >
        {
          field.asModal
            ? <React.Fragment>
              {
                ( isEmpty( availableOptions ) && isEmpty( items ) )
                  ? <EmptyState
                    message={ field.emptyMessage || `No ${field.label}'s available to add` }
                  />
                  : <React.Fragment>
                    <span
                      className="asModalSummary">
                      {/* eslint-disable-next-line max-len */}
                      { `${Object.keys( items ).length} ${ field.summaryMessage || `${field.label} (${Object.keys( items ).length})` } `}
                    </span>
                    <button
                      className="asModalTrigger"
                      onClick={ () => setShowModal( true ) }
                    >
                      { `Add ${field.label}` }
                    </button>
                    <Modal
                      elementClass="selectListFieldModal"
                      visible={showModal}
                      size="small"
                      setVisible={setShowModal}
                      header={ `Add ${field.label}` }
                      body={ <InputBody
                        onChange={onChange}
                        availableOptions={availableOptions}
                        items={items}
                        setItems={setItems}
                        field={field}
                        formState={formState}
                        selections={selections}
                        handleMultiSelectChange={handleMultiSelectChange}
                        handleSelection={handleSelection}
                        singleSelectValue={singleSelectValue}
                        onDragEnd={onDragEnd}
                      />
                      }
                    />
                  </React.Fragment>
              }

            </React.Fragment>
            : <InputBody
              onChange={onChange}
              availableOptions={availableOptions}
              items={items}
              setItems={setItems}
              field={field}
              formState={formState}
              selections={selections}
              handleMultiSelectChange={handleMultiSelectChange}
              handleSelection={handleSelection}
              singleSelectValue={singleSelectValue}
              onDragEnd={onDragEnd}
            />
        }

      </div>
    </React.Fragment>

  );
};

export default SelectList;
