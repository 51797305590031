/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

// a place to keep all of the context providers in one place

import { ModelProvider } from '../../Contexts/Model';
import { CurrentUserProvider } from '../../Contexts/CurrentUser';
import { HelpProvider } from '../../Contexts/Help';
import { OnboardingWizardProvider } from '../../Contexts/OnboardingWizard';
import { RemediationProvider } from '../../Contexts/Remediation';
import { FlashMessageQueueProvider } from '../../Contexts/FlashMessageQueue';
import { RiskProvider } from '../../Contexts/Risk';

import React from 'react';
import { BreakpointProvider } from '../../Contexts/Breakpoint';
import { NavigationProvider } from '../../Contexts/Navigation';
import { TagsProvider } from '../../Contexts/Tags';
import { FullScreenVisualProvider } from '../../Contexts/FullScreenVisual';
import { PrintingProvider } from '../../Contexts/Printing';

const ContextProviderWrapper = ( { children } ) => {
  return (
    <PrintingProvider>
      <BreakpointProvider>
        <HelpProvider>
          <RiskProvider>
            <ModelProvider>
              <FlashMessageQueueProvider>
                <CurrentUserProvider>
                  <OnboardingWizardProvider>
                    <RemediationProvider>
                      <NavigationProvider>
                        <TagsProvider>
                          <FullScreenVisualProvider>
                            { children }
                          </FullScreenVisualProvider>
                        </TagsProvider>
                      </NavigationProvider>
                    </RemediationProvider>
                  </OnboardingWizardProvider>
                </CurrentUserProvider>
              </FlashMessageQueueProvider>
            </ModelProvider>
          </RiskProvider>
        </HelpProvider>
      </BreakpointProvider>
    </PrintingProvider>
  );
};

export default ContextProviderWrapper;