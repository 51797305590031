/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

// Gauge charts are only used in a single dashboard widget to show peer percentile. More use-cases could evolve, but
// a custom visual was needed, as no off-the-shelf solution was available.

import React from 'react';
import { isNotEmpty, isEmpty } from '../Utilities';

import './Gauge.scss';

const Gauge = ( { data, total, displayLabel=false } ) => {

  const svgWidth = 90;
  const svgHeight = 55;

  const [ hoveredSegment, setHoveredSegment ] = React.useState( null );
  const [ offset, setOffset ] = React.useState( -1 );

  // I couldn't work out the math for the angles quite right so I am just adjusting the offset for the extreme
  // ends of the gauge manually to account for the fact the gauge is not actually a half-circle, but slightly under
  React.useEffect( ( ) => {
    if ( isNotEmpty( data ) && isNotEmpty( data.value ) ) {
      if ( offset < 15 ) {
        setOffset( -2 );
      }
      if ( data.value > 50 ) {
        setOffset( 1 );
      }
      if ( data.value > 85 ) {
        setOffset( 2 );
      }
    }
  }, [ data ] );

  return (
    <div className="svgGaugeWrapper">
      {
        ( isNotEmpty( data ) && isNotEmpty( total ) ) &&
        <svg
          viewBox={ `0 0 ${svgWidth} ${svgHeight}` }
          xmlns="http://www.w3.org/2000/svg"
          className="gaugeChart"
        >
          {/* background */}
          <circle
            cx="50%"
            cy="72%"
            r="30"
            strokeDasharray="90 110"
            strokeDashoffset="104"
            strokeWidth="10"
            stroke="#F7F7FA"
            fill="none"
          />
          {/* fill in part of the gauge, only one data point for a gauge */}
          <circle
            // eslint-disable-next-line max-len
            className={ `gaugeSegment ${ isNotEmpty( hoveredSegment ) && hoveredSegment === data.label ? 'isHovering' : '' } ${ isEmpty( hoveredSegment ) ? 'noHover' : '' }`}
            cx="50%"
            cy="72%"
            r="30"
            strokeWidth="10"
            fill="none"
            stroke={ data.stroke }
            strokeDasharray={ `${  ( data.value * 0.9 ) } ${ 200 - (  ( data.value * 0.9 ) ) }` }
            strokeDashoffset="104"
            onMouseEnter={ () => setHoveredSegment( data.label ) }
            onMouseLeave={ () => setHoveredSegment( null ) }
          />;
          {/* little circle */}
          <circle
            // eslint-disable-next-line max-len
            cx={ svgWidth / 2 }
            cy="39.6"
            r="3"
            fill="#EBEFF2"
          />;
          {/* line rather than trying to calculate the endpoints, just using transforms to rotate the line*/}
          <line
            x1="39.6"
            strokeWidth="2"
            stroke="#334D6E"
            strokeLinecap="round"
            // eslint-disable-next-line max-len
            transform={ `translate(${svgWidth / 2},39.6) rotate(${ ( Math.abs( ( 180 - ( ( data.value / 100 ) * 180 ) ) ) + offset ) * -1 })` }
          />
          {
            displayLabel &&
            <React.Fragment>
              <text
                x="50%"
                y="98%"
                fontSize="12"
                textAnchor="middle"
                alignmentBaseline="bottom"
                fill={ data.stroke }
              >
                { data.rawValue }
              </text>
            </React.Fragment>
          }
        </svg>
      }

    </div>
  );
};

export default Gauge;