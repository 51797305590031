/** **************************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ****************************************************************************/

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './components/App';

// NOTE: needed to remove React.StrictMode wrapper so that the draggable core
// (used by help and onboarding wizard) could function
const container = document.getElementById( 'react_container' );
const root = createRoot( container ); // createRoot(container!) if you use TypeScript
root.render( <App /> );
