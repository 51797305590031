/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

// component for displaying the list of tasks that are available and their status
// this component will poll the server every 6 seconds to get the latest status of the tasks
// and display them in the UI

import React from 'react';
import {
  decodeURLHash,
  isNotEmpty,
  recordSorter,
  getGlobalSettings,
} from '../../../shared/Utilities';
import { TasksContext } from '../../../Contexts/Tasks';
import { format_tz_offset } from '../../../../legacy/dom';
import { OnboardingWizardContext } from '../../../Contexts/OnboardingWizard';
import { makeRequest } from '../../../../legacy/io';
import TaskItem from './TaskItem.js';
import { hasFeatureAccess } from '../../App/AccessControl.js';
import { CurrentUserContext } from '../../../Contexts/CurrentUser.js';

const TaskList = ( ) => {

  // eslint-disable-next-line no-unused-vars
  const [ tasks, setTasks ] = React.useContext( TasksContext );
  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );

  const [ taskItems, setTaskItems ] = React.useState( {} );
  const [ scanners, setScanners ] = React.useState( {} );
  const [ scanGroups, setScanGroups ] = React.useState( {} );
  const [ currentTime, setCurrentTime ] = React.useState( null );

  const [ serverTimeZone, setServerTimeZone ] = React.useState( '' );

  const [ warnings, setWarnings ] = React.useState( {} );

  // ContextualHelp getters and setters
  const [ , , refreshWizard, , , , , , , ] = React.useContext( OnboardingWizardContext );

  // cannonical order of how the tasks should be displayed on the page
  const availableTasks = [
    'upgrade',
    'feed',
    'authenticated',
    'process',
    'import',
    'analysis',
  ];

  // var for the polling that will get cleared on cleanup
  let listPoll = null;

  // on page load, grab all the data needed, set up the polling, clear out on unmount
  React.useEffect( () => {
    let isMounted = true;

    initialize();

    clearInterval( listPoll );

    if ( isMounted ) {
      refreshTasks();
    }

    listPoll = setInterval( function() {
      refreshTasks();
    }, 6_000 );

    return () => {
      isMounted = false;
      clearInterval( listPoll );
    };
  }, [] );

  // get all the warnings anytime the tasks change
  React.useEffect( () => {
    if ( isNotEmpty( tasks ) ) {
      const _warnings = {};

      if ( isNotEmpty( tasks.current ) ) {
        Object.entries( tasks.current ).map( ( [ taskKey, task ] ) => {
          const _taskWarnings = task.warnings || [];

          if ( isNotEmpty( task.exception ) ) {
            _taskWarnings.push( task.exception );
          }

          _warnings[taskKey] = _taskWarnings;
        } );
      }
      setWarnings( _warnings );
    }
  }, [ tasks ] );

  React.useEffect( () => {
    getGlobalSettings( 'global' ).then( globalSettings => {
      const tz = globalSettings.server_tz;
      const offset = globalSettings.server_tzoffset;

      setServerTimeZone( `${tz} (UTC${format_tz_offset( offset )})` );
    } );
  }, [ ] );

  const initialize = async () => {

    const _scanGroups = {};
    const _scanners = {};
    const credIDs = {};

    // grab and format the scan groups
    let scanGroupsResponse = ( await ( makeRequest( 'FIND', '/project/default/scan_group', {} ) ) ).results;

    if ( isNotEmpty( scanGroupsResponse ) ) {
      scanGroupsResponse = scanGroupsResponse.sort( ( a, b ) => recordSorter( 'label', true, a, b ) );
    }

    scanGroupsResponse.map( sg => {
      _scanGroups[sg.id] = sg;
    } );

    // grab and format the vuln scanners
    // eslint-disable-next-line max-len
    const vulnerabilityScannersResponse = (
      await ( makeRequest( 'FIND', '/project/default/third_party_setting', { } ) )
    ).results;

    vulnerabilityScannersResponse.map( v => {
      if (
        isNotEmpty( v.settings )
        && v.category === 'vulnerability_scanner'
      ) {
        _scanners[v.id] = v;
      }
      if ( isNotEmpty( v.settings ) && isNotEmpty( v.settings.credential_id ) ) {
        credIDs[v.settings.credential_id] = v.id;
      }
    } );

    setScanGroups( _scanGroups );
    setScanners( _scanners );
  };

  const refreshTasks = async () => {

    const _tasks = {};

    const taskResponse = ( await ( makeRequest( 'LIST', '/task' ) ) ).results;
    const statusResponse = ( await ( makeRequest( 'STATUS', '/task', { project: 'default' } ) ) ).results;

    setCurrentTime( statusResponse.current_time );

    setTasks( statusResponse );

    availableTasks.map( taskID => {
      let taskKey = taskID;
      if ( taskID === 'process' ) {
        taskKey = 'authenticated';
      }
      _tasks[taskID] = {
        taskIdentifier: taskID,
        current: {},
        scheduled: [],
        ...taskResponse[taskKey],
      };

      if ( taskID === 'process' ) {
        _tasks.process.title = 'Process Scan Queue';
      }
    } );

    availableTasks.map( taskID => {
      let taskKey = taskID;
      if ( taskID === 'process' ) {
        taskKey = 'authenticated';
      }
      _tasks[taskID].current = statusResponse.current[taskKey] || {};
      _tasks[taskID].scheduled = statusResponse.scheduled[taskKey] || [];
    } );

    setTaskItems( _tasks );

    const parsedHash = decodeURLHash();

    if ( parsedHash.onboarding_step ) {
      if ( parsedHash.onboarding_step === '5' ) {
        _tasks.authenticated.focusForOnboarding = true;
      } else if ( parsedHash.onboarding_step === '4' ) {
        _tasks.process.focusForOnboarding = true;
      }
    }
    setTaskItems( _tasks );
    if (
      isNotEmpty( currentUser )
      && isNotEmpty( licenseInfo )
      && hasFeatureAccess( currentUser, licenseInfo, 'f_onboarding' )
    ) {
      refreshWizard();
    }
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( taskItems ) && isNotEmpty( currentTime ) ) &&
          <ul className="setupRecordsList">
            {
              Object.values( taskItems ).map( ( t, i ) => {
                return  <TaskItem
                  task={t}
                  key={i}
                  warnings={warnings}
                  serverTimeZone={serverTimeZone}
                  scanners={scanners}
                  scanGroups={scanGroups}
                  taskItems={taskItems}
                  refreshTasks={refreshTasks}
                  currentTime={currentTime}
                />;
              } )
            }
          </ul>
      }
    </React.Fragment>
  );
};

export default TaskList;