/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

// The ChartHoverIndicators allows area charts to be hovered over and a vertical line and dot will appear to more
// easily see all the data at a particular point. A click handler allows a card to display all of the information at a
// given point on the x axis. This is mainly used in the dashboard widgets.
// This is also a good example of html being styled to work and function alongside svg charts.

import React from 'react';
import ReactDom from 'react-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  categoryLabelMap,
  formatNumber,
  formatUnixDate,
  getDimensionsAndOffset,
  globalColors,
  isEmpty,
  isNotEmpty,
  // itemIsArray,
  pluralizeType,
} from '../Utilities';

import './ChartHoverIndicators.scss';
import {
  deprioritizedKeys,
  forReviewKeys,
  prioritizedKeys,
} from '../../components/Reporting/Dashboards/Widgets/v2/VulnerabilityInstancesCategories';
import { TagsContext } from '../../Contexts/Tags';
// import InlineSVG from '../InlineSVG';

// preferred stacking order from bottom to top,
// 1. deprirotized children
// 2. deprioritized
// 3. for_review children
// 4. for_review
// 5. prioritized children
// 6. prioritized
const preferredCategoryOrder = [
  ...deprioritizedKeys,
  'deprioritized',
  ...forReviewKeys,
  'for_review',
  ...prioritizedKeys,
  'prioritized',
];

const ChartHoverCard = ( {
  recordType,
  onClickCallback=() => {},
  areaColumn,
  currentHoverPoints,
  highestPointRef,
  areaHeight,
  withLegend,
  withXAxis,
} ) => {

  const [ attribute, setAttribute ] = React.useState( 'risk' );
  const [ cardLeft, setCardLeft ] = React.useState( null );
  const [ cardTop, setCardTop ] = React.useState( null );
  const cardWidth = 16 * 16;

  const portalRoot = document.getElementById( 'chartHoverCardPortal' );

  const cardRef = React.useRef( null );

  React.useEffect( () => {
    let _attribute = '';
    if ( recordType === 'hosts' ) {
      _attribute = 'num_hosts';
    }
    if ( recordType === 'patches' ) {
      _attribute = 'num_patches';
    }
    if ( recordType === 'vulnerabilities' ) {
      _attribute = 'num_vulnerabilities';
    }
    if ( recordType === 'vulnerability_instances' ) {
      _attribute = 'num_instances';
    }
    if ( recordType === 'risk' ) {
      _attribute = 'risk';
    }
    if ( recordType === 'riskOverTime' ) {
      _attribute = 'risk';
    }
    setAttribute( _attribute );
  }, [ recordType ] );

  // position the card centerered below the highest point
  React.useEffect( () => {
    if ( isNotEmpty( highestPointRef?.current ) && isNotEmpty( cardRef?.current ) ) {
      setTimeout( () => {
        const topPointEl = document.getElementById( 'hoverPoint_0' );
        const { left, top, width, height } = getDimensionsAndOffset( topPointEl );
        const { width: cardWidth, height: cardHeight } = getDimensionsAndOffset( cardRef.current );

        const _cardLeft = left - ( ( cardWidth - width ) / 2 );
        const _cardTop = top - cardHeight - ( height * 2 );
        setCardLeft( _cardLeft );
        setCardTop( _cardTop );
      }, 100 );

    }
  }, [ highestPointRef, cardRef, currentHoverPoints ] );

  const contentForRecordType = recordType => {
    if ( recordType === 'riskOverTime' ) {
      return <ul>
        <li>
          <span className="color" style={{ background: globalColors['darkBlue']}} />
          {/* eslint-disable-next-line max-len */}
          <span className="label">Risk Score: <strong>{ formatNumber( Math.floor( currentHoverPoints[0]?.original?.original?.risk ) ) }</strong></span>
        </li>
        <li>
          <span className="color" style={{ background: globalColors['status--blue']}} />
          {/* eslint-disable-next-line max-len */}
          <span className="label">Added Hosts: <strong>{ formatNumber( Math.floor( currentHoverPoints[0]?.original?.original?.addedHosts ) ) }</strong></span>
        </li>
        <li>
          <span className="color" style={{ background: globalColors['status--yellow']}} />
          {/* eslint-disable-next-line max-len */}
          <span className="label">Removed Hosts: <strong>{ formatNumber( Math.abs( Math.floor( currentHoverPoints[0]?.original?.original?.removedHosts ) ) ) }</strong></span>
        </li>
        <li>
          <span className="color" style={{ background: globalColors['status--red']}} />
          {/* eslint-disable-next-line max-len */}
          <span className="label">Added Instances: <strong>{ formatNumber( Math.floor( currentHoverPoints[0]?.original?.original?.addedEscalations ) ) }</strong></span>
        </li>
        <li>
          <span className="color" style={{ background: globalColors['status--green']}} />
          {/* eslint-disable-next-line max-len */}
          <span className="label">Removed Instances: <strong>{ formatNumber( Math.abs( Math.floor( currentHoverPoints[0]?.original?.original?.removedEscalations ) ) ) }</strong></span>
        </li>
      </ul>;
    }
    return <ul>
      {
        currentHoverPoints.map( ( p, i ) => {
          return <li key={i}>
            <span
              className="color"
              style={
                { background: p.original?.original?.isTagFill ? p.fill : globalColors[p.fill] }
              }
            />
            {/* eslint-disable-next-line max-len */}
            <span className="label">{ p.original?.label}: <strong>{ formatNumber( Math.floor( p.original?.original?.value ) ) }</strong></span>
          </li>;
        } )
      }
    </ul>;
  };

  return ReactDom.createPortal(
    <React.Fragment>
      {
        ( isNotEmpty( recordType ) && isNotEmpty( currentHoverPoints ) && isNotEmpty( attribute ) ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `hoverCard areaHeight--${areaHeight} ${withLegend ? 'withLegend' : ''} ${withXAxis ? 'withXAxis' : ''}`}
          onClick={ () => onClickCallback( areaColumn.points ) }
          ref={cardRef}
          style={
            {
              left: cardLeft,
              top: cardTop,
              width: cardWidth,
            }
          }
        >
          <h3>
            {/* <InlineSVG type="calendar" /> */}
            { formatUnixDate( currentHoverPoints[0]?.original?.timestamp, true ) }
          </h3>
          { contentForRecordType( recordType ) }
        </div>
      }
    </React.Fragment>,
    portalRoot,
  );
};

const ChartHoverIndicators = ( {
  data,
  onClickCallback=() => {},
  onHoverCallback=() => {},
  fill='darkBlue',
  currentHoverPoints,
  containerWidth,
  containerHeight,
  withLegend=false,
  heightClass='100',
  areaPosition='top',
  areaHeight='100',
  withXAxis=false,
  recordType='risk',
  multiArea=false,
  version='overlapping',
  svgContainerRef,
} ) => {

  const _containerHeight = containerHeight / 2;
  const svgHeight = _containerHeight;
  const svgWidth = containerWidth - 10;
  const xGutter = ( containerWidth - svgWidth ) / 2;
  const yGutter = 0;

  const pointWidth = 16;

  const [ hoverColumns, setHoverColumns ] = React.useState( null );
  const [ styledHoverColumns, setStyledHoverColumns ] = React.useState( null );
  const [ currentHoverColumn, setCurrentHoverColumn ] = React.useState( null );
  const [ tags ] = React.useContext( TagsContext );

  const highestPointRef = React.useRef( null );

  const setupHoverColumns = seriesData => {
    if ( isNotEmpty( seriesData ) && isNotEmpty( seriesData.series ) ) {

      const { max, series } = seriesData;

      const _hoverColumns = {};

      Object.values( series ).map( s => {
        let _hoverColumn = {};
        if ( isNotEmpty( s ) && isNotEmpty( s.points ) ) {

          const { points } = s;
          Object.values( points ).map( ( p, pointIndex ) => {

            const allPointsInSeries = [];

            Object.values( series ).map( _s => {
              if ( isNotEmpty( _s.points ) ) {

                const sameSeriesPoint = _s.points[p.timestamp];

                if ( isNotEmpty( sameSeriesPoint ) ) {
                  allPointsInSeries.push( sameSeriesPoint );
                }
              }
            } );

            if ( isNotEmpty( allPointsInSeries ) ) {
              let _points = [];
              let timestamp;
              allPointsInSeries.map( ( p, _i ) => {
                const seriesLength = Object.values( points ).length;
                const _heightRatio = svgHeight / max;

                const top = 100 - ( ( p.adjustedValue / max ) * 100 );

                // this assumes even spacing, which it won't always be
                let left = ( ( pointIndex ) / seriesLength ) * 100;

                const y = ( svgHeight - ( _heightRatio * p.adjustedValue ) ) + yGutter;

                const firstPointValue = Object.values( points )[0].timestamp;

                // eslint-disable-next-line max-len
                const lastPointValue = Object.values( points )[ seriesLength - 1].timestamp;
                const delta = lastPointValue - firstPointValue;
                const _widthRatio = svgWidth / delta;

                left = ( ( p.timestamp - firstPointValue ) / delta ) * 100;

                const x = ( _widthRatio * ( p.timestamp - firstPointValue ) ) + xGutter;

                const _height = svgHeight;

                ( { timestamp } = p );

                if ( isEmpty( timestamp ) ) {
                  ( { timestamp } = p.original );
                }

                // for the first point, copy this over to the main object
                if ( _i === 0 ) {

                  _hoverColumn = { x, y, top, left, timestamp, points: [] };
                }

                let id = p.original?.id;

                if ( isEmpty( id  ) ) {
                  id = p.original?.original?.id;
                }
                _points.push( { _height, x, y, id, original: p, top, left, fill: p.fill || 'darkBlue' } );
              } );

              _points = _points.sort( ( a, b ) =>  a.top - b.top );

              const top = Math.min( ..._points.map( p => p.top ) );
              const left = Math.min( ..._points.map( p => p.left ) );
              _hoverColumn.top = top;
              _hoverColumn.left = left;
              _hoverColumn.points = _points;

              _hoverColumns[timestamp] = _hoverColumn;
            }
          } );
        }
      } );

      setHoverColumns( _hoverColumns );
    } else {
      setHoverColumns( [] );
    }

  };

  // sets the max height for calculating points
  React.useEffect( ( ) => {
    if (
      isNotEmpty( data )
      && isNotEmpty( data.transformed )
      && isNotEmpty( version )
      && multiArea
    ) {
      const _seriesData = {
        max: version === 'stacked' ? 1 : data.max,
        series: {},
      };

      const [ referencePoint ] = Object.values( data.transformed );

      const _preferredTagOrder = [];

      // just set an arbitrary tag order so that the order is always the same from series to series
      if ( isNotEmpty( referencePoint ) && referencePoint.isTag && isNotEmpty( referencePoint.series ) ) {
        Object.entries( referencePoint.series ).map( ( [ assetTagID, series ] ) => {
          if ( series.isIncluded ) {
            _preferredTagOrder.push( assetTagID );
          }
        } );
      }
      if ( isNotEmpty( referencePoint ) && isNotEmpty( referencePoint.series ) ) {

        Object.entries( referencePoint.series ).map( ( [ seriesKey, seriesData ] ) => {
          _seriesData.series[seriesKey] = {
            seriesKey,
            fill: seriesData.fill,
            isTagFill: seriesData.isTagFill,
            max: seriesData.value,
            points: {},
            preferredIndex: referencePoint.isTag
              ? _preferredTagOrder.indexOf( seriesKey )
              : preferredCategoryOrder.indexOf( seriesKey ),
          };
        } );

        Object.entries( data.transformed ).map( ( [ timestamp, transformedData ], index ) => {

          const { pointTotal } = transformedData;

          // go through each of the series and map the data over to the transformed _seriesData object
          if ( isNotEmpty( transformedData.series ) ) {
            Object.entries( transformedData.series ).map( ( [ seriesKey, seriesData ] ) => {

              if ( isNotEmpty( _seriesData.series[seriesKey] ) ) {
                const { preferredIndex } = _seriesData.series[seriesKey];

                if ( isNotEmpty( preferredIndex ) ) {
                  let _seriesPoint = {};
                  let label = categoryLabelMap[seriesKey];

                  if ( isEmpty( label ) ) {
                    if ( isNotEmpty( tags ) && isNotEmpty( tags[seriesKey] ) ) {
                      ( { label } = tags[seriesKey] );
                    } else {
                      label = 'N/A';
                    }
                  }
                  // if this is a stacked version, we need to adjust the value by the values "below" it
                  if ( version === 'stacked' ) {
                    let prev = 0;
                    // looping through all the previous and adding them all up to get the value of the current
                    Object.entries( transformedData.series ).map( ( [ _key, _data ] ) => {
                      const _pi = seriesData.isTagFill
                        ? _preferredTagOrder.indexOf( _key )
                        : preferredCategoryOrder.indexOf( _key );

                      if ( _pi < preferredIndex ) {
                        prev += ( _data.value / pointTotal );
                      }
                    } );
                    _seriesPoint = {
                      label,
                      original: { ...seriesData, id: uuidv4() },
                      originalValue: seriesData.value,
                      adjustedValue: prev + ( seriesData.value / pointTotal ),
                      fill: seriesData.fill,
                      timestamp,
                      totalPoints: Object.keys( transformedData.series ).length,
                      originalIndex: index,
                    };
                  // otherwise just copy over the values
                  } else {
                    _seriesPoint = {
                      label,
                      original: { ...seriesData, id: uuidv4() },
                      originalValue: seriesData.value,
                      adjustedValue: seriesData.value,
                      fill: seriesData.fill,
                      timestamp,
                      totalPoints: Object.keys( transformedData.series ).length,
                      originalIndex: index,
                    };
                  }
                  _seriesData.series[seriesKey].points[timestamp] = _seriesPoint;
                }
              }
            } );
          }
        } );
      }

      // eslint-disable-next-line
      let _adjustedSeriesMax = version === 'stacked' ? 1 : data.max;

      // adjust the max for each series
      if ( isNotEmpty( _seriesData ) && isNotEmpty( _seriesData.series ) ) {
        Object.values( _seriesData.series ).map( series => {
          let { max } = series;

          if ( isNotEmpty( series.points ) ) {
            Object.values( series.points ).map( p => {
              if ( p.adjustedValue > max ) {
                max = p.adjustedValue;
              }
            } );

            series.max = max;
            if ( max < _adjustedSeriesMax ) {
              _adjustedSeriesMax = max;
            }
          }
        } );

        _seriesData.adjustedMax = _adjustedSeriesMax;
      }
      setupHoverColumns( _seriesData );
    } else if (
      isNotEmpty( data )
      && isNotEmpty( data.transformed )
      && isNotEmpty( data.max )
      && isNotEmpty( data.yAxis )
    ) {
      const _seriesData = {
        max: data.max,
        adjustedMax: data.max,
        series: {
          [recordType]: {
            seriesKey: recordType,
            fill: fill,
            isTagFill: false,
            max: data.max,
            points: {},
            preferredIndex: 0,
          },
        },
      };

      // go through each of the series and map the data over to the transformed _seriesData object
      if ( isNotEmpty(  data.transformed ) ) {
        Object.values(  data.transformed ).map( ( seriesData, index ) => {

          let _seriesPoint = {};

          _seriesPoint = {
            label: pluralizeType( recordType, true ),
            original: seriesData,
            originalValue: seriesData[data.yAxis],
            adjustedValue: seriesData[data.yAxis],
            fill: fill,
            timestamp: seriesData.timestamp,
            totalPoints: Object.keys( data.transformed ).length,
            originalIndex: index,
          };

          _seriesData.series[recordType].points[seriesData.timestamp] = _seriesPoint;
        } );
      }
      setupHoverColumns( _seriesData );
    }
  }, [ data, containerHeight, containerWidth, tags ] );

  React.useEffect( () => {
    if ( isNotEmpty( hoverColumns ) ) {
      const _columns = { ...hoverColumns };
      Object.values( _columns ).map( ( column, index ) => {
        let nextColumn = Object.values( hoverColumns )[index + 1 ];
        let previousColumn = Object.values( hoverColumns )[index - 1];

        if ( index === 0 ) {
          previousColumn = null;
        }
        if ( index === Object.values( hoverColumns ).length - 1 ) {
          nextColumn = null;
        }
        let columnLeft;
        let columnRight;
        let pointLeft;
        let columnWidth;

        // first column
        if ( isEmpty( previousColumn ) ) {
          columnLeft = column.left;
          columnRight = column.left + ( ( nextColumn.left - columnLeft ) / 2 );
          columnWidth = columnRight - columnLeft;
          pointLeft = columnLeft;
        // last column
        } else if ( isEmpty( nextColumn ) ) {
          columnLeft = previousColumn.left + ( ( column.left - previousColumn.left ) / 2 );
          columnRight = column.left;
          columnWidth = columnRight - columnLeft;
          pointLeft = columnLeft + columnWidth;
        // all other columns
        } else {
          columnLeft = previousColumn.left + ( ( column.left - previousColumn.left ) / 2 );
          columnRight = column.left + ( ( nextColumn.left - column.left ) / 2 );
          columnWidth = columnRight - columnLeft;
          pointLeft = columnLeft + ( columnWidth / 2 );
        }

        column.styleProperties = {
          left: columnLeft,
          leftStyle: `${columnLeft}%`,
          width: columnWidth,
          widthStyle: `${columnWidth}%`,
          right: columnRight,
          pointLeft,
          pointLeftStyle: `${pointLeft}%`,
        };
      } );
      setStyledHoverColumns( _columns );
    }
  }, [ hoverColumns ] );

  const handleColumnMouseEnter = column => {
    setCurrentHoverColumn( column );
    onHoverCallback( column.points );
  };

  const handleColumnMouseLeave = () => {
    setCurrentHoverColumn( null );
    onHoverCallback( null );
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( styledHoverColumns ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `${withXAxis ? 'withXAxis' : ''} chartHoverIndicatorsWrapper ${withLegend ? 'withLegend' : ''} heightClass--${heightClass} areaHeight--${areaHeight} areaPosition--${areaPosition}` }
        >
          <div
            // eslint-disable-next-line max-len
            className={ `areaPointsContainer areaHeight--${areaHeight} ${withLegend ? 'withLegend' : ''} ${withXAxis ? 'withXAxis' : ''}` }
          >
            {
              isNotEmpty( currentHoverColumn ) &&
              <React.Fragment>
                {/* vertical line */}
                <div
                  // eslint-disable-next-line max-len
                  className={ `hoverBar areaHeight--${areaHeight} ${withLegend ? 'withLegend' : ''} ${withXAxis ? 'withXAxis' : ''}`}
                  onClick={ () => onClickCallback( currentHoverColumn.points ) }
                  style={
                    {
                      width: '2px',
                      background: globalColors[fill || 'darkBlue' ],
                      left: currentHoverColumn.styleProperties?.pointLeftStyle,
                    }
                  }
                />
                {/* the hover points (dots, circles) */}
                {
                  currentHoverColumn.points.map( ( p, i ) => {
                    return <div
                      // eslint-disable-next-line max-len
                      className="hoverPoint"
                      id={ `hoverPoint_${i}` }
                      onClick={ () => onClickCallback( currentHoverColumn.points ) }
                      key={i}
                      ref={ i === 0 ? highestPointRef : null }
                      style={
                        {
                          height: pointWidth,
                          width: pointWidth,
                          border: `2px solid ${p.original?.original?.isTagFill ? p.fill : globalColors[p.fill]}`,
                          boxShadow: `0 0 5px ${p.original?.original?.isTagFill ? p.fill : globalColors[p.fill]}`,
                          top: `calc( ${p.top}% - ${( pointWidth / 4 )}px )`,
                          left: currentHoverColumn.styleProperties?.pointLeftStyle,
                          marginLeft: `calc(-${pointWidth / 2}px + 1px)`,
                        }
                      }
                    />;
                  } )
                }
                {/* hover card to display info about the current point(s) */}
                <ChartHoverCard
                  recordType={ recordType }
                  onHoverCallback={ onHoverCallback }
                  areaColumn={ currentHoverColumn }
                  currentHoverPoints={currentHoverPoints}
                  pointWidth={ pointWidth }
                  pointLeft={ currentHoverColumn.styleProperties?.pointLeftStyle }
                  areaHeight={ areaHeight }
                  withLegend={ withLegend }
                  withXAxis={ withXAxis }
                  fill={ globalColors[fill || 'darkBlue' ] }
                  svgContainerRef={svgContainerRef}
                  highestPointRef={highestPointRef}
                />
              </React.Fragment>
            }
            {
              Object.values( styledHoverColumns ).map( ( column, index ) => {
                return <React.Fragment key={ index } >
                  <div
                    // eslint-disable-next-line max-len
                    className={ `fullHoverContainer areaHeight--${areaHeight} ${withLegend ? 'withLegend' : ''} ${withXAxis ? 'withXAxis' : ''}`}
                    onMouseEnter={ () => handleColumnMouseEnter( column ) }
                    onMouseLeave={ handleColumnMouseLeave }
                    onClick={ () => onClickCallback( column.points ) }
                    style={
                      {
                        width: column.styleProperties?.widthStyle,
                        left: column.styleProperties?.leftStyle,
                      }
                    }
                  >
                  </div>
                </React.Fragment>;
              } )
            }
          </div>
        </div>
      }
    </React.Fragment>
  );
};

export default ChartHoverIndicators;