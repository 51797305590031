/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

// Forms can be divided into FieldGroups for styling purposes

import React from 'react';

import Field from './Field';

import './FieldGroup.scss';

import {
  isNotEmpty,
  isEmpty,
  isFormNull,
} from '../Utilities';
import { CurrentUserContext } from '../../Contexts/CurrentUser';
import { canConfigure } from '../../components/App/AccessControl';

const FieldGroup = ( {
  fields,
  group,
  setExternalFormState,
  externalFormState,
  formState,
  setFormState,
  editMode,
  trackUpdates,
  existingRecord,
  recordType,
  validateOnLoad,
} ) => {

  const [ groupIsEmpty, setGroupIsEmpty ] = React.useState( false );
  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );

  React.useEffect( () => {
    if  (
      isNotEmpty( group )
          && isNotEmpty( group.fields )
          && isNotEmpty( formState )
          && isNotEmpty( formState.fieldStates )
    ) {

      const _included = [];

      group.fields.map( field => {
        const state = formState.fieldStates[field.attribute];
        if ( state.included ) {
          _included.push( field.attribute );
        }
      } );

      setGroupIsEmpty( isEmpty( _included ) );
    }
  }, [ group, formState ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( group ) &&
        <div
          // eslint-disable-next-line max-len
          className={ `${!canConfigure( licenseInfo ) ? 'demoMode' : ''} fieldGroupWrapper ${group.asSentence ? 'asSentence' : '' } ${group.inline ? 'inline' : '' } ${group.name} ${( groupIsEmpty || isEmpty( group.fields ) ) ? 'isEmpty' : ''}` }
        >
          {
            group?.header &&
            <div className="sectionHeader">
              { group.header }
              { group?.help && group.help }
            </div>
          }
          {
            isNotEmpty( group.fields ) &&
            group.fields.map( ( field, index ) => {
              let originalValue = '';
              if (
                isNotEmpty( formState )
                && isNotEmpty( formState.fieldStates )
                && isNotEmpty( formState.fieldStates[field.attribute] )
              ) {
                if ( isNotEmpty( existingRecord ) ) {
                  originalValue = formState.fieldStates[field.attribute].originalValue || '';
                } else {
                  originalValue = field.defaultValue;
                }
              }
              if ( isFormNull( originalValue ) && ( field.type === 'password' || field.type === 'text' ) ) {
                originalValue = '';
              }
              return  <Field
                key={index}
                field={field}
                fields={fields}
                setExternalFormState={setExternalFormState}
                externalFormState={externalFormState}
                formState={formState}
                setFormState={setFormState}
                originalValue={originalValue}
                editMode={editMode}
                trackUpdates={trackUpdates}
                existingRecord={existingRecord}
                recordType={recordType}
                validateOnLoad={validateOnLoad}
                inline={group.inline}
                asSentence={group.asSentence}
              />;
            } )
          }
        </div>
      }
    </React.Fragment>
  );
};

export default FieldGroup;
