/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

// The x and y axis lines are html elements that are rendered as html, and separate from the SVG element. This was
// mainly done to have better control over the thickness of the lines. As SVGs stretch, the lines would become thicker
// and the dashes would become too close together or far apart.

import React from 'react';
import { isNotEmpty } from '../../Utilities';

import './style.scss';

const YAxisLines = ( {
  lineCount=3,
  lineStyle='dashed',
  elementClass='',
} ) => {

  return (
    <React.Fragment>
      {
        isNotEmpty( lineCount ) &&
        <div className={ `axisLinesContainer yAxis lineCountClass--${lineCount} ${lineStyle} ${elementClass}` } >
          { Array( lineCount ).fill().map( ( p, i ) => <div key={i} className="YAxisLineBox" /> ) }
        </div>
      }
    </React.Fragment>
  );
};

export default YAxisLines;